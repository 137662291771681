/* Container geral */
.formulario-container {
  width: 100%; /* Altere max-width para width para garantir que ocupe toda a largura */
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Centralização de Títulos */
.titulo-centralizado {
  text-align: center;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  font-size: 2em;
}

.subtitulo-centralizado {
  text-align: center;
  color: #555;
  margin-bottom: 20px;
  font-size: 1.5em;
}

/* Contêiner de Ingredientes */
.ingredientes-container {
  width: 100%; /* Ajustar para ocupar toda a largura disponível */
  margin: 20px auto;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  line-height: 1.6;
}

/* Input de Ingredientes */
.input-container {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}

.input-container input {
  flex: 1;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Botões */
.botao-adicionar, .botao-enviar, .botao-nova-receita {
  background-color: #ff6347; /* Tom de laranja */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.botao-adicionar:hover, .botao-enviar:hover, .botao-nova-receita:hover {
  background-color: #e5533f; /* Escurece ao passar o mouse */
}

.botao-enviar {
  margin-left: 10px; /* Espaçamento entre os botões */
}

/* Video Loading */
.video-container {
  text-align: center;
  margin-top: 20px;
}

/* Resposta */
.resposta-container {
  width: 100%; /* Ajustar largura para 100% */
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Tabela de nutrientes */
.tabela-nutrientes {
  width: 100%; /* Ocupará todo o espaço disponível */
  border-collapse: collapse;
  margin-top: 15px;
  margin-bottom: 15px;
  table-layout: fixed;
}

.tabela-nutrientes th,
.tabela-nutrientes td {
  border: 1px solid #ccc;
  padding: 12px;
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}

.tabela-nutrientes th {
  background-color: #f0f0f0;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  padding: 16px;
}

.tabela-nutrientes tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Imagem da Receita */
.menor-imagem {
  width: 80%; /* Aumentar a largura da imagem para 80% */
  height: auto;
  display: block;
  margin: 20px auto;
  border-radius: 10px;
}

/* Título Nutrientes */
.nutrientes-titulo {
  font-size: 1.4em;
  font-weight: bold;
  margin-top: 20px;
  color: #333;
}

/* Botão nova receita */
.botao-nova-receita {
  background-color: #ff6347;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.botao-nova-receita:hover {
  background-color: #e5533f;
}
